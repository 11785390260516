<template>
    <b-modal id="addpricemodal" modal-class="modal-right-pane">
        <template v-slot:modal-header="{ close }">
            <h2>{{ policy ? $t('edit_price_policy') : $t('create_price_policy') }}</h2>
            <a class="pointer position-relative modal-close" @click="close()">
                <CloseSvg/>
            </a>
        </template>
        <div>
            <h3>{{ $t('title') }}</h3>
            <InputGroup :label="$t('title')" class="default" type="text" v-model="form.title" @enter="save"/>
            <h3>{{ $t('price') }} ({{ $store.state.user.currency }})</h3>
            <InputGroup :label="$t('price_per_lesson')" class="default" type="number" v-model="form.price" @enter="save"/>
        </div>
        <template v-slot:modal-footer>
            <b-button v-if="policy" @click="destroy" :disabled="loading" class="btn-themed btn-white"><TrashSvg/></b-button>
            <b-button class="btn-themed" :disabled="!valid || loading" @click="save">{{ $t('save') }}</b-button>
        </template>
    </b-modal>
</template>

<script>
    import CloseSvg from '@/assets/svg/close.svg'
    import InputGroup from "@/views/parts/general/form/InputGroup"
    import { PricePoliciesService } from '@/services/api.service'
    import TrashSvg from '@/assets/svg/trash2.svg'

    export default {
        components: {
            CloseSvg, InputGroup, TrashSvg
        },
        data() {
            return {
                form: {
                    title: "",
                    price: null,
                },
                loading: false,
            }
        },
        props: {
            policy: {}
        },
        watch: {
            policy(val) {
                if(val) {
                    this.form = {
                        title: val.title,
                        price: val.price
                    }
                } else {
                    this.form = {
                        title: "",
                        price: null,
                    }
                }
            }
        },
        mounted() {
        },
        computed: {
            valid() {
                return this.form.title && this.form.price
            }
        },
        methods: {
            save() {
                if(this.loading) { return }
                this.loading = true
                if(this.policy) {
                    PricePoliciesService.update(this.policy.id, this.form).then(() => {
                        this.loading = false
                        this.$emit('reload')
                        this.$bvModal.hide('addpricemodal')
                    }).catch((err) => {
                        this.errResponse(err)
                        this.loading = false
                    })
                } else {
                    PricePoliciesService.add(this.form).then((res) => {
                        this.loading = false
                        this.$emit('reload', res.data.data)
                        this.$bvModal.hide('addpricemodal')
                    }).catch(() => this.loading = false)
                }
            },
            destroy() {
                this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
                    this.loading = true
                    PricePoliciesService.delete(this.policy.id).then(() => {
                        this.loading = false
                        this.$success(this.$t('price_policy_deleted'))
                        this.$bvModal.hide('addpricemodal')
                        this.$emit('reload')
                    }).catch((err) => {
                        this.errResponse(err)
                        this.loading = false
                    })
                })
            },
        }
    }
</script>

<style scoped lang="scss">

</style>


