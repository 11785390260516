<template>
    <section id="settings-price-policies">
        <h2 class="pb-2">{{ $t('price_policies') }}</h2>
        <a class="btn-whole-add btn-whole-add-dashed" @click="addPrice">
            <span class="svg-wrapper">
                <PlusSvg class="svg-icon-sm"/>
            </span>
            {{ $t('create_price_policy') }}
        </a>
        <AddPricePolicyModal @reload="getPolicies" :policy="addingnew ? null : selectedprice"/>
        <div class="mt-3">
            <div v-for="(p,pind) in policies" :key="'policy-'+pind" class="price-policy-teaser">
                <b-row class="align-items-center">
                    <b-col>
                        {{ p.title }}
                    </b-col>
                    <b-col md="3" cols="5">
                        <div class="text-muted">
                            {{ p.price | formatNumber }} {{ $store.state.user.currency }} / {{ $t('lesson') }}
                        </div>
                    </b-col>
                    <b-col cols="auto" v-if="[p.instructor_id, p.school_id].includes($store.state.user.id)">
                        <a class="edit-btn" @click="edit(p)">
                            <EditSvg class="svg-icon-sm"/>
                        </a>
                    </b-col>
                </b-row>
            </div>
        </div>
    </section>
</template>

<script>
    import EventBus from '@/services/event-bus'
    import { PricePoliciesService } from '@/services/api.service'
    import PlusSvg from '@/assets/svg/plus.svg'
    import EditSvg from '@/assets/svg/pen.svg'
    import AddPricePolicyModal from '@/views/pages/instructor/settings/AddPricePolicyModal'

    export default {
        components: {
            PlusSvg, AddPricePolicyModal, EditSvg
        },
        data() {
            return {
                policies: [],
                selectedprice: null,
                addingnew: false
            }
        },
        mounted() {
            EventBus.$emit('breadcrumbs',[
                { to: '/settings', title: this.$t('settings') },
                { to: '/settings/policies', title: this.$t('price_policies') },
            ])
            this.getPolicies()
        },
        methods: {
            addPrice() {
                this.selectedprice = null
                this.addingnew = true
                this.$bvModal.show('addpricemodal')
            },
            getPolicies() {
                PricePoliciesService.get().then(res => {
                    console.log(res.data.data)
                    this.policies = res.data.data
                })
            },
            edit(policy) {
                this.selectedprice = policy
                this.addingnew = false
                this.$bvModal.show('addpricemodal')
            }
        }
    }
</script>

<style scoped lang="scss">
    .price-policy-teaser {
        padding: 24px 0;
        border-bottom: 1px solid $border-color;
        .edit-btn {
            margin-left: 15px;
            background: $gray-back2;
            width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border-radius: 100%;
            display: inline-block;
            cursor: pointer;
        }
    }
</style>


