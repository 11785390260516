import { render, staticRenderFns } from "./PricePolicies.vue?vue&type=template&id=665974f4&scoped=true&"
import script from "./PricePolicies.vue?vue&type=script&lang=js&"
export * from "./PricePolicies.vue?vue&type=script&lang=js&"
import style0 from "./PricePolicies.vue?vue&type=style&index=0&id=665974f4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665974f4",
  null
  
)

export default component.exports